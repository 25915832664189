<template>
  <div v-cloak id="login">
    <div class="bgimg">
      <div class="PageCen">
        <div class="title">大科智慧监测云平台</div>
        <div class="InputBox">
          <div class="zhang">欢迎登录</div>
          <div class="InputSingle">
            <img src="../assets/images/zlogo.png" alt="" />
            <input
              v-model="loginForm.account"
              type="text"
              placeholder="请输入账号"
              @keyup.enter="submitForm"
            />
          </div>

          <div class="InputSingle">
            <img src="../assets/images/miLogo.png" alt="" />
            <input
              v-model="loginForm.password"
              :type="[flag ? 'text' : 'password']"
              class="input"
              placeholder="请输入密码"
              @keyup.enter="submitForm"
            />
          </div>
          <!-- <div class="Security flex between">
							<div class="InputSingle">
							<img src="../assets/images/yan.png" alt="">
								<input type="text" placeholder="验证码" v-model="loginForm.captcha">
							</div>
							<img class="Verification" :src="img" alt="" @click="GetCodeImg">
						</div> -->
          <div class="chenck">
            <el-checkbox v-model="checked" @change="checkedC"
              >记住密码</el-checkbox
            >
          </div>
          <div class="LonginBtn" @click="submitForm">登 录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: false,
      // account: '18516520205',
      // password: '123456',

      img: '',
      checked: false,
      // 登录表单的数据绑定对象
      loginForm: {
        account: '',
        password: '',
        captcha: '',
      },
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        account: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },
      checked: '',
    }
  },
  mounted: function () {
    this.GetCodeImg()

    if (localStorage.getItem('account')) {
      this.checked = true
      this.account = localStorage.getItem('account')
      this.password = localStorage.getItem('password')
    }
  },
  methods: {
    // 获取图像验证码
    // GetCodeImg() {
    // 	request('POST', '/other/getCaptcha', {}, res => {
    // 		if (res.code == 1) {
    // 			_this.img = res.data.image
    // 		} else {
    // 			_this.$message(res.msg)
    // 		}
    // 	}, err => {
    // 		_this.$message(err.msg)
    // 	})
    // }, -->
    async GetCodeImg() {
      const res = await this.$http.post('other/getCaptcha')
      if (res.code !== 1) return this.$message.error(res.msg)
      this.img = res.data.image
    },
    checkedC(val) {
      if (val == true) {
        window.localStorage.setItem('account', this.loginForm.account)
        window.localStorage.setItem('password', this.loginForm.password)
      } else {
        window.localStorage.removeItem('account')
        window.localStorage.removeItem('password')
        // 	_this.account = ''
        // _this.password = ''
      }
    },
    async submitForm() {
      //   this.$router.push('home.vue')
      // 简单处理下 账户密码验证规则
      if (this.loginForm.account === '' || this.loginForm.password === '') {
        console.log('用户名或者密码没有输入')
        this.$message.error('用户名或者密码不能为空！')
      } else {
        const res = await this.$http.post('user/login', this.loginForm)
        if (res.code !== 1) return this.$message.error(res.msg)
        this.$message.success('登录成功')
        window.sessionStorage.setItem('token', res.data.token)
        setTimeout(() => {
          this.$router.push('/home')
        }, 700)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: 100%;
  display: block;
}
.bgimg {
  /*  背景图定位 / 背景图尺寸  cover 完全铺满容器  contain 完整显示在容器内 */
  width: 100%;
  height: 100vh;
  // min-height: 800px;
  background: url(../assets/images/loginBg.png) no-repeat #000;
  background-size: 100% 100%;
  /* 行高是字体1.15倍 */
  // line-height: 1.15;
  background-color: #f2f2f2;
  position: relative;
}
.title {
  position: absolute;
  top: 10px;
  // right: 220.8px;
  font-size: 50px;
  font-family: Alimama DongFangDaKai;
  font-weight: 400;
  color: #68d8fe;

  background: linear-gradient(0deg, #009ff1 0%, #00e8cf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.PageCen {
  width: 1200px;
  height: 100%;
  /* background-image: url(../img/14.png); */
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.InputBox {
  /* width: 80%; */
  width: 511px;
  height: 500px;
  background-image: url(../assets/images/loginK.png);
  background-size: 100% 100%;
  padding: 37px 87px 61px 99px;
  box-sizing: border-box;
  // margin-top: 324px;
  // margin-left: 994px;
  text-align: center;
  position: absolute;
  right: 100px;
  top: 25%;
}
.zhang {
  /* width: 1.75rem; */
  margin-top: 30px;
  font-family: HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 40px;
  color: #ffffff;
}
.InputSingle {
  border: 2px solid #38547f;
  border-radius: 4px;
  height: 50px;
  padding: 20px 20px;
  /* opacity: .2; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 26px;
}
.InputSingle img {
  width: 21px;
  height: 24px;
}
.InputBox > div {
  /* margin-top: .275rem; */
}

.InputSingle i {
  color: white;
  font-size: 30px;
}
.tit {
  position: absolute;
  left: 114px;
  top: 42px;
  font-size: 22px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}
.InputSingle input {
  width: 90%;
  flex: 1;
  height: 50px;
  font-size: 18px;
  color: #fff !important;
  padding-left: 11px;
  box-sizing: border-box;
  background-color: initial;
  outline: none;
  border: none;
}
.Security {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chenck {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
  margin-left: 8px;
}
.LonginBtn {
  margin-top: 30px;
  /* width: 360px; */
  height: 50px;
  background: #20a3f5;
  border-radius: 6px;
  text-align: center;
  line-height: 50px;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.InputSingle input::-webkit-input-placeholder {
  color: #fff !important;
  font-size: 18px;
  font-family: SourceHanSansCN;
  font-weight: 500;
}

.Verification {
  margin-left: 2%;
  width: 8vw;
  height: 50px;
  margin-top: 26px;
}
.el-checkbox__inner {
  background-color: rgba(0, 0, 0, 0) !important;
}
.el-checkbox__label {
  color: #ffffff !important;
}
</style>
